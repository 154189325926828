class Form extends HTMLFormElement {
  constructor() {
    super();

    this.addEventListener("input", handleInput);
    this.addEventListener("submit", handleSubmit);

    Object.defineProperty(this, "cleanup", {
      enumerable: false,
      value: () => {
        this.removeEventListener("input", handleInput);
        this.removeEventListener("submit", handleSubmit);
      },
    });
  }

  connectedCallback() {
    // disable native html5 form validation
    // and let javascript handle this
    this.setAttribute("novalidate", "novalidate");

    for (let input of this.querySelectorAll("input")) {
      if (!input.hasAttribute("pattern")) {
        const maxlength = input.getAttribute("maxlength");
        const minlength = input.getAttribute("minlength");
        if (minlength || maxlength) {
          const pattern = `^.{${minlength || 0},${maxlength || ""}}$`;
          input.setAttribute("pattern", pattern);
          input.removeAttribute("maxlength");
          input.removeAttribute("minlength");
        }
      }
    }
  }

  disconnectedCallback() {
    this.cleanup();
  }
}

function handleInput(event) {
  validate(event.target);
}

function handleSubmit(event) {
  let valid = true;
  for (let element of this.elements) {
    if (element.tagName === "INPUT" && !validate(element)) {
      valid = false;
    }
  }
  if (!valid) {
    event.preventDefault();
  }
}

function validate(element) {
  if (element.type === "hidden" || element.tagName === "BUTTON") {
    return true;
  }

  const formGroupElement = element.parentNode.parentNode;
  const errorMessageElement = formGroupElement.querySelector(".form-error-feedback");

  if (element.checkValidity()) {
    element.classList.remove("form-invalid");
    const siblings = element.parentNode.querySelectorAll("input");
    if (siblings.length === 1) {
      if (errorMessageElement) {
        errorMessageElement.textContent = "";
      }
    } else {
      if ([...siblings].every((sibling) => sibling.checkValidity()) && errorMessageElement) {
        errorMessageElement.textContent = "";
      }
    }
    return true;
  } else {
    const message = element.dataset.errorMessage;
    if (message && errorMessageElement) {
      errorMessageElement.textContent = message;
    }
    element.classList.add("form-invalid");
    return false;
  }
}

customElements.define("uv-form", Form, { extends: "form" });
